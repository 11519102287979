<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="姓名:">
          <el-input
        	 v-model.trim="searchForm.name" 
        	 	@keyup.enter.native="seach"
        	 clearable />
        </el-form-item>
        <el-form-item label="手机:">
          <el-input 
        		v-model.trim="searchForm.mobile" 
        		@keyup.enter.native="seach"
        		clearable />
        </el-form-item>
        <el-form-item label="微信:">
          <el-input 
        		v-model.trim="searchForm.weixin" 
        		@keyup.enter.native="seach"
        		clearable />
        </el-form-item>
				<el-form-item label="QQ:">
				 <el-select 
						v-model="searchForm.isqq" 
						@visible-change="visibleIsqq"
						@keyup.enter.native="seach"
						ref="isqqref"
						clearable>
					<el-option value="是"></el-option>
					<el-option value="否"></el-option>
				 </el-select>
				</el-form-item>
				<el-form-item label="教学点是否分配:">
				 <el-select 
						v-model="searchForm.isschooldisflag" 
						@visible-change="visibleIschooldisflag"
						@keyup.enter.native="seach"
						ref="disflagref"
						clearable>
					<el-option value="是"></el-option>
					<el-option value="否"></el-option>
				 </el-select>
				</el-form-item>
				<el-form-item label="总部是否分配:">
				 <el-select 
						v-model="searchForm.ishqdisflag" 
						@visible-change="visibleIshqdisflag"
						@keyup.enter.native="seach"
						ref="hqdisflagref"
						clearable>
					<el-option value="是"></el-option>
					<el-option value="否"></el-option>
				 </el-select>
				</el-form-item>
				<el-form-item label="分公司是否分配:">
				 <el-select 
						v-model="searchForm.iscompanydisflag" 
						@visible-change="visibleIscompanydisflag"
						@keyup.enter.native="seach"
						ref="companydisflagref"
						clearable>
					<el-option value="是"></el-option>
					<el-option value="否"></el-option>
				 </el-select>
				</el-form-item>
        <el-form-item label="咨询项目:">
          <el-input 
        		v-model.trim="searchForm.askforval"
        		@keyup.enter.native="seach"
        	 clearable />
        </el-form-item>
        <el-form-item label="客服:">
          <el-input 
        		v-model.trim="searchForm.insertusername"
        		@keyup.enter.native="seach"
        	 clearable />
        </el-form-item>
        <el-form-item label="跟进人:">
          <el-input 
        		v-model.trim="searchForm.followusername" 
        		@keyup.enter.native="seach"
        		clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input
        		v-model.trim="searchForm.schoolorgname"
        		@keyup.enter.native="seach"
        	 clearable />
        </el-form-item>
				<el-form-item label="录入时间起:">
				  <el-date-picker
				    v-model="searchForm.t1"
				    type="date"
				    placeholder="选择日期"
				    value-format="yyyy-MM-dd 00:00:00"
				  >
				  </el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间止:">
				  <el-date-picker
				    v-model="searchForm.t2"
				    type="date"
				    placeholder="选择日期"
				    value-format="yyyy-MM-dd 00:00:00"
				  >
				  </el-date-picker>
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button
				type="primary"
				class="btn-2b5a95"
				@click="resetQuery"
			>
				重置
			</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 列表表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
        label="姓名"
        prop="name"
        align="center"
    		width="80px"
      >
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">
    			{{
            scope.row.name
          }}
    			</el-button>
        </template>
      </el-table-column>
      <el-table-column label="手机" prop="mobile" align="center" width="130px">
    		<template slot-scope="scope" v-if="scope.row.mobile !== null">
    			  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
    			</template>
    	</el-table-column>
      <el-table-column label="微信" prop="weixin" align="center" />
      <el-table-column label="咨询项目" prop="askforval" align="center"/>
      <el-table-column label="城市" prop="cityval" align="center" />
    	<el-table-column label="录入时间" prop="inserttime" align="center" width="100px">
    		<template slot-scope="scope">
    		    {{ scope.row.inserttime | formatDate("YYYY-MM-DD") }}
    		  </template>
    	</el-table-column>
    	<el-table-column label="总部/分配时间" align="center" width="140px">
    	<template slot-scope="scope">
    		    {{ scope.row.ishqdisflag == 1 ? "是" : "否" }} / {{ scope.row.hqdistime | formatDate("YYYY-MM-DD") }}
    		</template>
    	</el-table-column>
    	<el-table-column label="分公司/分配" prop="iscompanydisflag" align="center" width="140px">
    		<template slot-scope="scope">
    			 {{scope.row.companyorgname}}/ {{ scope.row.enableflag == 1 ? "是" : "否" }}
    		</template>
    	</el-table-column>
    	<el-table-column label="教学点/分配" prop="isschooldisflag" align="center" width="140px">
    		<template slot-scope="scope">
    			{{scope.row.schoolorgname}} /{{ scope.row.enableflag == 1 ? "是" : "否" }}
    		</template>
    	</el-table-column>
    	<el-table-column label="客服" prop="insertusername" align="center" />
    	<el-table-column label="跟进人" prop="followusername" align="center" />
    	<el-table-column 
    		label="跟进内容"
    		prop="lastfollowcount"
    		:show-overflow-tooltip="true" 
    		align="center" />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
    	@close="onCloseAdd"
    	:close-on-click-modal='false'
      append-to-body
    >
			<el-tabs v-model="activeName" type="border-card" @tab-click="onTabsClick">
			  <el-tab-pane label="成人高考信息" name="adultCollege">
					<el-form
					   :model="dynamicValidateForm"
					   ref="dynamicValidateForm"
					   label-width="30%"
					   class="demo-dynamic form-item-w-50"
					   status-icon
					   :rules="rules"
					 >
					   <el-form-item prop="name" label="学生姓名:">
					     <el-input
					       v-model.trim="dynamicValidateForm.name"
					     ></el-input>
					   </el-form-item>
					   <el-form-item label="手机:" prop="mobile">
					     <el-input
					       v-model.trim="dynamicValidateForm.mobile"
					       placeholder="请输入正确手机号码"
								 @input="changeMobile"
								 @blur="checkIsmobile"
					     />
					   </el-form-item>
					   <el-form-item label="QQ:" prop="qq">
					     <el-input
					       v-model.trim="dynamicValidateForm.qq"
								 placeholder="请输入qq"
					    		clearable
					     ></el-input>
					   </el-form-item>
					   <el-form-item label="电子邮箱:" prop="email">
					     <el-input 
							 v-model.trim="dynamicValidateForm.email" 
							 placeholder="请输入电子邮箱"
							 @blur="changEmail"
							 clearable></el-input>
					   </el-form-item>
					    		
					   <el-form-item label="微信:" prop="weixin">
					     <el-input 
								v-model.trim="dynamicValidateForm.weixin" 
								placeholder="请输入微信"
								clearable></el-input>
					   </el-form-item>
					    		<el-form-item label="性别:" prop="sexkey">
					    			<el-select
					    			  v-model="dynamicValidateForm.sexval"
					    			  @change="(val, prop) => onChangeSelect(val, 'sexkey')"
					    			  clearable
					    			>
					    			  <el-option value="1" label="男"></el-option>
					    			  <el-option value="2" label="女"></el-option>
					    			</el-select>
					    		</el-form-item>
					    		<el-form-item label="意向院校:" prop="college">
					    		  <el-input v-model.trim="dynamicValidateForm.college" clearable></el-input>
					    		</el-form-item>
					    		<el-form-item label="意向专业:" prop="major">
					    		  <el-input v-model.trim="dynamicValidateForm.major" clearable></el-input>
					    		</el-form-item>
					    		<el-form-item label="是否购买教材:" prop="bookbuyflag">
					    			<el-select 
					    				v-model="dynamicValidateForm.bookbuyflag" 
					    				@change="selectbook"
					    				clearable>
					    				<el-option v-for="item in bookList"
					    					:key="item.key"
					    					:label="item.val"
					    					:value="item.key"
					    				>
					    				</el-option>
					    			</el-select>
					    		</el-form-item>
					    		<el-form-item label="城市名称:" prop="citykey">
					    		  <el-select
					    		    v-model.trim="dynamicValidateForm.citykey"
					    		    placeholder="请选择"
					    		    clearable
					    		  >
					    		    <el-option
					    		      v-for="item in selectcityList"
					    		      :key="item.key"
					    		      :label="item.val"
					    		      :value="item.key"
					    		    >
					    		    </el-option>
					    		  </el-select>
					    		</el-form-item>
					    		<el-form-item label="班别类型:" prop="classtypename">
					    		  <el-input
					    		    v-model.trim="dynamicValidateForm.classtypename"
					    		    disabled
					    		    placeholder="请选择"
					    		  />
					    		  <el-button
					    		    type="primary"
					    		    class="select-btn"
					    		    @click="
					    		      onSelectData(
					    		        fudaoclasstype_dialogresult,
					    		        'showClassModal',
					    		      )
					    		    "
					    		    >选择</el-button
					    		  >
					    		</el-form-item>
					    		<el-form-item label="授课地点:" prop="schoolname">
					    		  <el-input 
					    				v-model="dynamicValidateForm.schoolname" 
					    				disabled
					    		    placeholder="请选择"/>
					    				 <el-button
					    				   type="primary"
					    				   class="select-btn"
					    				   @click="
					    				     reportSelectBtn(
					    				       vschoolorg_dialogresult,
					    				       'showSchoolorgModal',
					    				     )
					    				   "
					    				   >选择</el-button
					    				 >
					    		</el-form-item>
					    		<el-form-item prop="levelkey" label="报考层次:">
					    		  <el-select
					    		    v-model="dynamicValidateForm.levelkey"
					    		    placeholder="请选择"
					    		    clearable
					    		  >
					    		    <el-option
					    		      v-for="item in leveltypeList"
					    		      :key="item.key"
					    		      :label="item.val"
					    		      :value="item.key"
					    		    >
					    		    </el-option>
					    		  </el-select>
					    		</el-form-item>
					    		<el-form-item prop="studenttypeval" label="类型:">
					    		  <el-select
					    		    v-model="dynamicValidateForm.studenttypeval"
					    		    placeholder="请选择"
					    		    clearable
					    		  >
					    		    <el-option
					    		      v-for="item in registertypeList"
					    		      :key="item.key"
					    		      :label="item.val"
					    		      :value="item.key"
					    		    >
					    		    </el-option>
					    		  </el-select>
					    		</el-form-item>
					    		<el-form-item prop="edukey" label="学历:">
					    		  <el-select
					    		    v-model="dynamicValidateForm.edukey"
					    		    placeholder="请选择"
					    		    clearable
					    		  >
					    		    <el-option
					    		      v-for="item in eduList"
					    		      :key="item.key"
					    		      :label="item.val"
					    		      :value="item.key"
					    		    >
					    		    </el-option>
					    		  </el-select>
					    		</el-form-item>
					    		<el-form-item prop="fromtypename" label="学生来源:">
					    		  <el-select
					    		    v-model="dynamicValidateForm.fromtypename"
					    		    placeholder="请选择"
					    		    clearable
					    		  >
					    		    <el-option
					    		      v-for="item in fromtypeList"
					    		      :key="item.key"
					    		      :label="item.val"
					    		      :value="item.val"
					    		    >
					    		    </el-option>
					    		  </el-select>
					    		</el-form-item>
					    		<el-form-item prop="askforkey" label="项目类型:">
					    		  <el-select
					    		    v-model="dynamicValidateForm.askforkey"
					    		    placeholder="请选择"
					    		    clearable
					    		  >
					    		    <el-option
					    		      v-for="item in educationList"
					    		      :key="item.key"
					    		      :label="item.val"
					    		      :value="item.key"
					    		    >
					    		    </el-option>
					    		  </el-select>
					    		</el-form-item>
					    		<el-form-item prop="addr" label="详细地址:">
					    			<el-input v-model.trim="dynamicValidateForm.addr" clearable />
					    		</el-form-item>
					    		<el-form-item prop="question" label="备注:">
					    			<el-input v-model.trim="dynamicValidateForm.question" clearable />
					    		</el-form-item>
					 </el-form>
						<span style="float: right;">
					   <el-button
					      type="primary"
					      @click="submitAddOrEditForm('dynamicValidateForm')"
					      :loading="!$store.state.global.isEndRequest"
					      >提交
					    </el-button>
						</span>
				</el-tab-pane>
				<el-tab-pane label="聊天记录" name="chatList">
					<div class="search-form btn-2b5a95" style="padding-top: 50px;">
					   <el-form
					   	:model="dynamicValidateForm"
					   	ref="dynamicValidateForm"
					   	status-icon
					   >
					   	<el-form-item label="内容:" prop="remark">
					   	  <div id="networkregister_editor" ref="editor"></div>
					   	</el-form-item>
					   </el-form>
					</div> 
				</el-tab-pane>
			 </el-tabs>	
    </el-dialog>
    <!-- 选择类型 -->
    <el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
    	:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择班别："
      :visible.sync="showClassModal"
    	v-if="showClassModal"
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmClass">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="班别名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
							clearable />
            </el-form-item>
            <el-form-item label="班别类型:" prop="fudaotypeval">
              <el-input
								v-model.trim="selectSearchForm.fudaotypeval" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
							 clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button
						type="primary"
						@click="handleRest"
					>
						重置
					</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
    
    
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="班别类型" prop="fudaotypeval" align="center" />
        <el-table-column label="班别编号" prop="code" align="center" />
        <el-table-column label="班别名称" prop="name" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择教学点 -->
    <el-dialog
      class="btn-2b5a95"
      @close="onCloseSelect"
			v-dialogDrag
    	:close-on-click-modal='false'
      title="选择教学点："
      :visible.sync="showSchoolorgModal"
      v-if="showSchoolorgModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmSchoolorg">确定</el-button>
    
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="机构简称:" prop="fullname">
              <el-input 
								v-model.trim="selectSearchForm.fullname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button
						type="primary"
						@click="handleRest"
					>
						重置
					</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
    
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="机构简称" prop="fullname" align="center" />
        <el-table-column label="分公司名称" prop="companyorgname" align="center" />
    	</el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :title="dialogTitle"
    	:close-on-click-modal='false'
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
       :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      append-to-body
      :fullscreen="$store.state.global.isFullscreen"
    >
      <span slot="title" class="el-dialog__title">
        <span>查看详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-tabs v-model="activeList" type="card" @tab-click="handleClickPay">
        <el-tab-pane label="查看详情" name="details">
    			<el-form
    			  :model="dynamicValidateForm"
    			  ref="dynamicValidateForm"
    			  label-width="40%"
    			  class="demo-dynamic form-item-w-50"
    			  status-icon
    			  :rules="rules"
    			>
    			  <el-form-item label="流水号:">
    			    {{ dynamicValidateForm.id }}
    			  </el-form-item>
    			  <el-form-item label="学生姓名:">
    			    {{ dynamicValidateForm.name }}
    			  </el-form-item>
    			  <el-form-item label="手机:">
    			    {{ dynamicValidateForm.mobile }}
    			  </el-form-item>
    			  <el-form-item label="QQ:">
    			    {{ dynamicValidateForm.qq }}
    			  </el-form-item>
    			  <el-form-item label="电子邮箱:">
    			    {{ dynamicValidateForm.email }}
    			  </el-form-item>
    			  <el-form-item label="性别:">
    			    {{ dynamicValidateForm.sexval}}
    			  </el-form-item>
    			  <el-form-item label="是否购买教材:">
    					<template>
    						{{ dynamicValidateForm.bookbuyflag == 1 ? "是" : "否"}}
    					</template>
    			  </el-form-item>
    			  <el-form-item label="微信:">
    			    {{ dynamicValidateForm.weixin }}
    			  </el-form-item>
    			  <el-form-item label="意向院校:">
    			    {{ dynamicValidateForm.college }}
    			  </el-form-item>
    			  <el-form-item label="意向专业:">
    			    {{ dynamicValidateForm.major }}
    			  </el-form-item>
    			  <el-form-item label="学历名称:">
    			    {{ dynamicValidateForm.eduval }}
    			  </el-form-item>
    			  <el-form-item label="类型名称:">
    			    {{ dynamicValidateForm.studenttypeval }}
    			  </el-form-item>
    			  <el-form-item label="班级类型名称:">
    			    {{ dynamicValidateForm.classtypename }}
    			  </el-form-item>
    			  <el-form-item label="星级名称:">
    			    {{ dynamicValidateForm.starval }}
    			  </el-form-item>
    			  <el-form-item label="学生来源名称:">
    			    {{ dynamicValidateForm.fromtypename }}
    			  </el-form-item>
    			  <el-form-item label="报考层次名称:">
    			    {{ dynamicValidateForm.levelval }}
    			  </el-form-item>
    				<el-form-item label="城市名称:">
    				  {{ dynamicValidateForm.cityval }}
    				</el-form-item>
    				<el-form-item label="咨询项目名称:">
    				  {{ dynamicValidateForm.askforval }}
    				</el-form-item>
    				<el-form-item label="所属分公司名称:">
    				  {{ dynamicValidateForm.companyorgname }}
    				</el-form-item>
    				<el-form-item label="所属教学点名称:">
    				  {{ dynamicValidateForm.schoolorgname }}
    				</el-form-item>
    				<el-form-item label="录入人名称:">
    				  {{ dynamicValidateForm.insertusername}}
    				</el-form-item>
    				<el-form-item label="录入时间:">
    				  {{ dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")}}
    				</el-form-item>
    				<el-form-item label="分配人名称:">
    				  {{ dynamicValidateForm.hqdisusername }}
    				</el-form-item>
    				<el-form-item label="分配时间:">
    				  {{ dynamicValidateForm.hqdistime | formatDate("YYYY-MM-DD HH:mm:ss") }}
    				</el-form-item>
    				<el-form-item label="跟进人名称:">
    				  {{ dynamicValidateForm.followusername }}
    				</el-form-item>
    				<el-form-item label="分公司分配人:">
    				  {{ dynamicValidateForm.companydisusername }}
    				</el-form-item>
    				<el-form-item label="授课地点:">
    				  {{ dynamicValidateForm.schoolname}}
    				</el-form-item>
    				<el-form-item label="详细地址:">
    				  {{ dynamicValidateForm.addr }}
    				</el-form-item>
    				<el-form-item label="咨询情况:">
    				  {{ dynamicValidateForm.question }}
    				</el-form-item>
    			</el-form>
        </el-tab-pane>
        <el-tab-pane label="聊天记录详情" name="chatCode">
         <el-form
           :model="dynamicValidateForm"
           ref="dynamicValidateForm"
           label-width="40%"
           class="demo-dynamic form-item-w-50"
           status-icon
           :rules="rules"
         >
    			<el-form-item label="内容:">
    			  <div v-html="dynamicValidateForm.remark"></div>
    			</el-form-item>
         </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 分配分公司-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      title="分配分公司"
      :visible.sync="companyModal"
      v-if="companyModal"
    	:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="queryParams"
        ref="queryParams"
        label-width="140px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
    		<el-form-item label="学生姓名:" prop="name">
    		  <el-input
    		    disabled
    		    v-model.trim="queryParams.name"
    		  ></el-input>
    		</el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="queryParams.companyorgname"
            placeholder="请选择"
            @change="onChangeCompany($event, 'queryParams')"
          >
            <el-option
              v-for="(item,index) in $store.state.selectData.companyList"
              :key="index"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitCompanyForm('queryParams')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 分配教学点-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      title="分配教学点"
      :visible.sync="schoolModal"
      v-if="schoolModal"
    	:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="queryParams"
        ref="queryParams"
        label-width="140px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
    		<el-form-item label="学生姓名:" prop="name">
    		  <el-input
    		    disabled
    		    v-model.trim="queryParams.name"
    		  ></el-input>
    		</el-form-item>
       <el-form-item prop="schoolorgname" label="所属教学点">
         <el-select
           v-model="queryParams.schoolorgname"
           placeholder="请选择"
           clearable
           @change="onChangeSchool($event, 'queryParams')"
         >
           <el-option
             v-for="item in $store.state.selectData.schoolList"
             :key="item.key"
             :label="item.val"
             :value="item.key"
           >
           </el-option>
         </el-select>
       </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitShoolForm('queryParams')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 分配招生员-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      title="分配招生员"
      :visible.sync="studentModal"
      v-if="studentModal"
    	:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="140px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
    		<el-form-item label="学生姓名:" prop="name">
    		  <el-input
    		    disabled
    		    v-model.trim="dynamicValidateForm.name"
    		  ></el-input>
    		</el-form-item>
      <el-form-item prop="companyorgname" label="所属分公司:">
      	<el-input v-model="dynamicValidateForm.companyorgname" disabled></el-input>
      </el-form-item>
    		<el-form-item label="员工姓名:" prop="inusername">
    		  <el-input v-model="dynamicValidateForm.inusername" disabled />
    		  <el-button
    		    type="primary"
    		    class="select-btn"
    		    @click="
    		      onSelectData(
    		        user_dialogresult,
    		        'showInusernameModal',
    		        Object.assign(
    		          { companyorgid: dynamicValidateForm.companyorgid },
    		          selectPageInfo
    		        )
    		      )
    		    "
    		    >选择</el-button
    		  >
    		</el-form-item>
    		
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitStudentForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    
    <!-- 选择职员 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择职员"
      :visible.sync="showInusernameModal"
      v-if="showInusernameModal"
    	:close-on-click-modal='false'
      append-to-body
    >
      <el-button
        type="primary"
        @click="onConfirmInusername('showInusernameModal')"
        >确定</el-button
      >
    
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({ companyorgid: dynamicValidateForm.companyorgid }, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({ companyorgid: dynamicValidateForm.companyorgid }, selectSearchForm)
              )"
            >搜索</el-button
          >
					<el-button type="primary" @click="selectRest">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
    
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="真实姓名" prop="realname" align="center" />
        <el-table-column label="所属部门" prop="departorgname" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
         (val, data) =>
             handleSelectChange(val, Object.assign({ companyorgid: dynamicValidateForm.companyorgid }, selectSearchForm))
         "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import E from "wangeditor";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";

import {
	networkregister2_listz,
	vschoolorg_dialogresult,
	fudaoclasstype_dialogresult,
	networkregister_save,
	networkregister_delete,
	networkregister_input,
	networkregister_checkinput,
	networkregister_checkqq,
	networkregister_checkmobile,
	networkregister_show,
	networkregister_discompanylist,
	networkregister_checkdiscompany,
	networkregister_savedistocompany,
	networkregister_checkdischool,
	networkregister_disschoollist,
	networkregister_savedisschool,
	networkregister_checkdisstudent,
	networkregister_disstudentlist,
	networkregister_savedisstudent,
	
} from "@/request/api/allChildrenProject";
import {
	dic_combox,
	_fudaoclasstype_dialogresult,
	_user_dialogresult,
	_fileupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
export default {
  mixins: [part],
  components: { Export },
  props: {
   fudaoclasstype_dialogresult: {
     default: () => _fudaoclasstype_dialogresult,
   },
	 vschoolorg_dialogresult: {
	   default: () => vschoolorg_dialogresult,
	 },
	 user_dialogresult: {
	   default: () => _user_dialogresult,
	 },
  },
  data() {
    return {
			bookList: [
				{
					val:'否',
					key: 0,
				},
				{
					val:'是',
					key: 1,
				},
			],
			showInusernameModal: false,//职员
			studentModal: false,//招生员
			schoolModal: false,//教学点
			queryParams:{},//分公司
			companyModal: false,//分公司
			activeList: "details",
			showDetailModal: false,//查看
			showSchoolorgModal: false,//教学点
			showClassModal: false,//班别类型
			showAddModal: false, // 添加--修改
      activeName: "adultCollege",
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 页数查询
			editor: null,//富文本
      multipleSelection: [],
      dynamicValidateForm: {},
      searchForm: {},
      rules: {
        name: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				askforkey: [{ trigger: "blur", message: "必填字段", required: true }],
				companyorgname:[{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTable: {},
			tableRes: {},
      selectTableUrl: "",
      selectSearchForm: {},
			selectcityList:[],
			leveltypeList:[],
			registertypeList:[],
			eduList:[],
			key: '',
			fromtypeList:[],
			fds: new FormData(),
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		this.getPulicList("selectcity","selectcityList");//城市名称
		this.getPulicList("leveltype","leveltypeList"); //层次 
		this.getPulicList("registertype","registertypeList"); //类型 
		this.getPulicList("edu","eduList"); //学历
		this.getPulicList("education","educationList"); //项目类型
		this.getPulicList("fromtype","fromtypeList"); //学生来源
		window.addEventListener("keyup", this.enterSelect);
  },
	mounted() {
		
	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild","dic_combox"]),
		//改变分公司 change
		onChangeCompany(val, form) {
		  this[form].companyorgid = val;
		  this.org_comboxchild(val);
		},
		onChangeSchool(val, form) {
		  this[form].schoolorgid = val;
			this.org_comboxchild(this.queryParams.companyorgid);
		},
		selectbook(vId) {
			let obj = {};
			obj = this.bookList.find((item)=>{ 
			    return item.key === vId;
			});
			this.dynamicValidateForm.bookbuyflag = obj.key;
			this.dynamicValidateForm.book = obj.val;
		},
		//changeMobile 手机号码
		changeMobile() {
			this.dynamicValidateForm.mobile = this.dynamicValidateForm.mobile.replace(/[^\d]/g,"");
			if(this.dynamicValidateForm.mobile.length > 11) {
				this.dynamicValidateForm.mobile = this.dynamicValidateForm.mobile.substr(0,11);
			}
			var reg=/^1[2|3|4|5|6|7|8|9][0-9]{9}$/;
			if(this.dynamicValidateForm.mobile.length >= 11 && !reg.test(this.dynamicValidateForm.mobile)) {
				 return this.$message("请输入正确的手机号格式")
			}
		},
		checkIsmobile() {
			if(this.dynamicValidateForm.mobile.length > 1 && !/^1[2|3|4|5|6|7|8|9][0-9]{9}$/.test(this.dynamicValidateForm.mobile)) {
				this.$message("请输入正确的手机号格式")
			}
		},
		//changEmail 电子邮箱
		changEmail() {
			this.dynamicValidateForm.email = this.dynamicValidateForm.email.replace(/[^\d]/g,"");
			const verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
			if(!verify.test(this.dynamicValidateForm.email) || this.dynamicValidateForm.email == null) {
				return this.$message('邮箱格式错误，请重新输入')
			}
		},
    //改变选择框 change
    onChangeSelect(val, prop) {
      this.dynamicValidateForm[prop] = val;
    },
		reportSelectBtn(url, modal, data) {
			if(this.dynamicValidateForm.qq == null && this.dynamicValidateForm.mobile == null && this.dynamicValidateForm.weixin == null) {
				return messageTip({
				  type: "warning",
				  message: '请填写QQ 或者手机号码或者微信其中一个',
				});
			}
		  this.onSelectData(url, modal, data);
		},
    //tabs选项卡 click
    onTabsClick(tab, event) {
				//console.log(tab,event,'-----')
    },
		// 获取公共数组方法
		getPulicList(params, list) {
		  myRequest({
		    url: dic_combox,
		    data: {
		      typecode: params,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this[list] = res.data.data.list;
		    }
		  });
		},
		// 查看详情 tab切换 change
		handleClickPay(tab, event) {
					
		},
		rest() {
			this.searchForm = {
				name: null,
				mobile: null,
				weixin: null,
				askforval: null,
				insertusername: null,
				followusername: null,
				schoolorgname: null,
				isqq: null,
				isschooldisflag: null,
				ishqdisflag: null,
				iscompanydisflag: null
			}
		},
		//重置 click
		resetQuery() {
			this.rest();
			this.getDataList();
		},
		//搜索 click
		seach() {
		  this.pageinfo.pageindex = 1;
		  this.getDataList();
		},
		
		handleRest() {
			this.selectSearchForm = {
				name: null,
				fudaotypeval: null,
				fullname: null,
				realname: null,
			},
			this.onSelectData(this.selectTableUrl,null,null)
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleIsqq(e) {
			if(!e) {
				this.$refs.isqqref.blur();
			}
			
		},
		visibleIschooldisflag(e) {
			if(!e) {
				this.$refs.disflagref.blur();
			}
			
		},
		visibleIshqdisflag(e) {
			if(!e) {
				this.$refs.hqdisflagref.blur();
			}
			
		},
		visibleIscompanydisflag(e) {
			if(!e) {
				this.$refs.companydisflagref.blur();
			}
			
		},
		selectRest() {
			this.selectSearchForm = {
				realname: null,
			},
			this.onSelectData(this.selectTableUrl,null,{companyorgid: this.dynamicValidateForm.companyorgid })
		},
		// 查看详情路由页 api
		btnShowDetail(row) {
		  myRequest({
		    url: 	networkregister_show,
		    data: {
		      id: row.id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.showDetailModal = true;
		      this.dialogTitle = "查看详情";
		      this.dynamicValidateForm = {};
		      this.dynamicValidateForm = res.data.data.tnetworkregister;
		    }
		  });
		},
		//初始化富文本编辑器 event
		initEditor() {
		  const that = this;
			this.$nextTick(() => {
			this.editor = new E ("#networkregister_editor");
			this.editor.config.withCredentials = true;
			this.editor.config.uploadFileName = "upfile";
			this.editor.config.customUploadImg = function (
			 resultFiles,
			 insertImgFn
			) {
			  that.fds.append("upfile", resultFiles[0]); // 传文件
			 myRequest({
			   method: "post",
			   url: _fileupload_save,
			   data: that.fds,
			 }).then((res) => {
			   if (res.data.code === "200") {
			     that.fds = new FormData();
			      //上传图片，返回结果，将图片插入到编辑器中
			     insertImgFn(that.$url.upload + res.data.url);
			   }
			  });
			};
			this.editor.config.onchange = (html) => {
			 this.dynamicValidateForm.remark = html;
			};
			this.editor.create();
			this.editor.txt.html(this.dynamicValidateForm.remark);
			});
		},
		//添加click
		btnAdd() {
		  this.dialogTitle = "添加";
		  this.dynamicValidateForm = {
		    remark: this.dynamicValidateForm.remark
		  };
			this.showAddModal = true;
			this.initEditor();
		},
		//修改click
		btnEdit() {
		  selectCheck(this.multipleSelection, "修改", false, () => {
				this.dialogTitle = "修改";
		    myRequest({
		      url: networkregister_checkinput,
					data: {
						id: this.multipleSelection[0].id,
					}
		    }).then((res) => {
		      if (res.data.code === "200") {
						this.showAddModal = true;
		        this.editRoutePage(this.multipleSelection[0].id);
		      }
		    });
		  });
		},
		///检查手机号是否已经存在
		Checkmobile() {
		  myRequest(
		    {
		      url: networkregister_checkmobile,
		      data: {
		        mobile: this.dynamicValidateForm.mobile,
						id: this.dynamicValidateForm.id
		      },
		    }).then((res) => {
					console.log(res,'几句话')
				if(res.data.code == 200) {
				
				}
			})
		},
		//检查qq是否已经存在 
		Checkqq() {
		  myRequest(
		    {
		      url: networkregister_checkqq,
		      data: {
						qq: this.dynamicValidateForm.qq,
						id: this.dynamicValidateForm.id
					}
		    }).then((res) => {
					console.log(res,'几句话')
					if(res.data.code == 200) {
					
					}
			
			})
		},
		
		//修改路由页 api
		editRoutePage(id) {
		  myRequest({
		    url: networkregister_input,
		    data: {
		      id: id
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
					this.dynamicValidateForm =  res.data.data.tnetworkregister;
					this.dynamicValidateForm.book = res.data.data.tnetworkregister.bookbuyflag;
					this.initEditor();
					if(this.editor) {
						this.editor.txt.html(this.dynamicValidateForm.remark);
					};
					delete this.dynamicValidateForm.inserttime;
		    }
		  });
		},
		//提交-（添加，修改） api
 		submitAddOrEditForm(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
					this.Checkmobile();
					this.Checkqq();
					this.save();
		    }
		  });
		},
		save() {
			myRequest(
			  {
			    url: networkregister_save,
			    data: this.dynamicValidateForm,
			  },
			  {
			    isRefTableList: true,
			    that: this,
			    modal: "showAddModal",
			  }).then((res) => {
						if(this.editor) {
							this.editor.txt.html("");
						}
						this.editor.txt.clear();
				})	
		},
		
		//分配至分公司
		btnCompany() {
			selectCheck(this.multipleSelection, "修改", true, () => {
			  myRequest({
					method: "post",
			    url: networkregister_checkdiscompany,
					data: this.$qs.stringify(
					  {
					    id: this.multipleSelection.map((i) => i.id),
					  },
					  {
					    arrayFormat: "repeat",
					  }
					),
			  }).then((res) => {
			    if (res.data.code === "200") {
						this.companyModal = true;
			      this.companyRoutePage();
			    }
			  });
			});
		},
		//路由--分配分公司
		companyRoutePage() {
			myRequest({
				method: "post",
			  url: networkregister_discompanylist,
			  data: this.$qs.stringify(
			    {
			      id: this.multipleSelection.map((i) => i.id),
			    },
			    {
			      arrayFormat: "repeat",
			    }
			  ),
			}).then((res) => {
			  if (res.data.code === "200") {
						this.queryParams = Object.assign(
							res.data.data.tnetworkregister,
							res.data.data.list,
							res.data.data.networkregisterlist,
						);
						this.$set(
						  this.queryParams,
						  "name",
						  res.data.data.list.map((i) => i.name).join("，")
						);
			  }
			});
		},
		//提交--分配分公司
		submitCompanyForm(formName) {
			this.$refs[formName].validate((valid) => {
			  if (valid) {
			    myRequest(
			      {
							method: "post",
			        url: networkregister_savedistocompany,
							data: this.$qs.stringify(
							  {
							    id: this.multipleSelection.map((i) => i.id),
									companyorgid: this.queryParams.companyorgid,
							  },
							  {
							    arrayFormat: "repeat",
							  }
							),
			      },
			      {
			        isRefTableList: true,
			        that: this,
			        
			        modal: "companyModal",
			      }
			    );
			  }
			});
		},
		//分配教学点
		btnSchool() {
			selectCheck(this.multipleSelection, "修改", true, () => {
			  myRequest({
					method: "post",
			    url:  networkregister_checkdischool,
					data: this.$qs.stringify(
					  {
					    id: this.multipleSelection.map((i) => i.id),
					  },
					  {
					    arrayFormat: "repeat",
					  }
					),
			  }).then((res) => {
			    if (res.data.code === "200") {
						this.schoolModal = true;
						this.schoolRoutePage();
			    }
			  });
			});
		},
		//路由--分配教学点
		schoolRoutePage() {
			myRequest({
				method: "post",
			  url: networkregister_disschoollist,
			  data: this.$qs.stringify(
			    {
			      id: this.multipleSelection.map((i) => i.id),
			    },
			    {
			      arrayFormat: "repeat",
			    }
			  ),
			}).then((res) => {
			  if (res.data.code === "200") {
					this.queryParams = Object.assign(
						res.data.data.tnetworkregister,
						res.data.data.networkregisterlist,
					);
					this.org_comboxchild(this.queryParams.companyorgid);
					this.$set(
					  this.queryParams,
					  "name",
					  res.data.data.networkregisterlist.map((i) => i.name).join("，")
					);
					this.queryParams.schoolorgid =
					this.$store.state.login.userinfo.schoolorgid;
			  }
			});
		},
		//提交--分配教学点
		submitShoolForm(formName) {
			this.$refs[formName].validate((valid) => {
			  if (valid) {
			    myRequest(
			      {
							method: "post",
			        url: networkregister_savedisschool,
							data: this.$qs.stringify(
							  {
							   id: this.multipleSelection.map((i) => i.id),
							   schoolorgid: this.queryParams.schoolorgid,
							  },
							  {
							    arrayFormat: "repeat",
							  }
							),
			      },
			      {
			        isRefTableList: true,
			        that: this,
			        
			        modal: "schoolModal",
			      }
			    );
			  }
			});
		},
		//分配招生员
		btnStudent() {
			selectCheck(this.multipleSelection, "修改", true, () => {
			  myRequest({
					method: "post",
			    url: networkregister_checkdisstudent,
					data: this.$qs.stringify(
					  {
					    id: this.multipleSelection.map((i) => i.id),
					  },
					  {
					    arrayFormat: "repeat",
					  }
					),
			  }).then((res) => {
			    if (res.data.code === "200") {
						this.studentModal = true;
						delete this.dynamicValidateForm.hqdistime;
						delete this.dynamicValidateForm.companydistime;
						delete this.dynamicValidateForm.schooldistime;  
			      this.studentRoutePage();
			    }
			  });
			});
		},
		//路由--分配招生员
		studentRoutePage() {
			myRequest({
				method: "post",
			  url: networkregister_disstudentlist,
			  data: this.$qs.stringify(
			    {
			      id: this.multipleSelection.map((i) => i.id),
			    },
			    {
			      arrayFormat: "repeat",
			    }
			  ),
			}).then((res) => {
			  if (res.data.code === "200") {
					this.dynamicValidateForm = Object.assign(
						res.data.data.tnetworkregister,
						res.data.data.networkregisterlist,
					);
					this.dynamicValidateForm.companyorgid	 = res.data.data.tnetworkregister.companyorgid;
					this.$set(
					  this.dynamicValidateForm,
					  "name",
					  res.data.data.networkregisterlist.map((i) => i.name).join("，")
					);
					this.$set(
					  this.dynamicValidateForm,
					  "id",
					  res.data.data.networkregisterlist.map((i) => i.id)
					);
					delete this.dynamicValidateForm.inserttime
					delete this.dynamicValidateForm.hqdistime;
					delete this.dynamicValidateForm.companydistime;
					delete this.dynamicValidateForm.schooldistime;  
			  }
			});
		},
		//分配至招生员
		submitStudentForm(formName) {
			this.$refs[formName].validate((valid) => {
			  if (valid) {
			    myRequest(
			      {
							method: "post",
			        url: networkregister_savedisstudent,
							data: this.$qs.stringify(
								 {
								  id: this.dynamicValidateForm.id,
									followuserid: this.dynamicValidateForm.followuserid,
								 },
								 {
								   arrayFormat: "repeat",
								 }
							)
			      },
						{
			        isRefTableList: true,
			        that: this,
			        
			        modal: "studentModal",
			      }).then((res) => {
							if(res.data.code == 200) {
								this.studentModal = false;
							}
						})
			  }
			});
		},
		//确认选择职员 click
		onConfirmInusername(modal) {
		  selectCheck(this.multipleSelection, "选择职员", false, () => {
		    this.$set(
		      this.dynamicValidateForm,
		      "inusername",
		      this.multipleSelection[0].realname
		    );
		    this.dynamicValidateForm.followuserid = this.multipleSelection[0].id;
		    this.showInusernameModal = false;
				this.multipleSelection = [];
				this.selectRest() 
		  });
		},
		//确认班别 click
		onConfirmClass() {
		  selectCheck(this.multipleSelection, "确认班别", false, () => {
		   this.$set(this.dynamicValidateForm,'classtypename',this.multipleSelection[0].name)
		    this.$set(this.dynamicValidateForm,'fudaotypeval',this.multipleSelection[0].fudaotypeval)
		    this.dynamicValidateForm.fudaoclasstypeid = this.multipleSelection[0].id;
		    this.showClassModal = false;
				this.multipleSelection = [];
				this.handleRest();
		  });
		},
		//确认教学点
		onConfirmSchoolorg() {
		  selectCheck(this.multipleSelection, "选择", false, () => {
		    this.$set(
		      this.dynamicValidateForm,
		      "schoolname",
		      this.multipleSelection[0].fullname
		    );
		    this.$set(
		      this.dynamicValidateForm,
		      "companyorgname2",
		      this.multipleSelection[0].companyorgname
		    );
		    this.dynamicValidateForm.schoolid = this.multipleSelection[0].id;
		    this.dynamicValidateForm.companyorgid2 =
		      this.multipleSelection[0].companyorgid;
		    this.showSchoolorgModal = false;
				this.multipleSelection = [];
				this.handleRest();
		  });
		},
		//删除click
		btnDel() {
		  selectCheck(this.multipleSelection, "删除", true, () => {
		    confirmCallBack({
		      title: "提示",
		      content: "此操作将永久删除选中的数据！",
		      success: () => {
		        this.delTableItem();
		      },
		      fail: () => {
		        messageTip({
		          message: "取消操作",
		          type: "info",
		        });
		      },
		    });
		  });
		},
		//删除 api
		delTableItem() {
		  myRequest(
		    {
		      method: "post",
		      url: networkregister_delete,
		      data: this.$qs.stringify(
		        {
		          id: this.multipleSelection.map((i) => i.id),
		        },
		        {
		          arrayFormat: "repeat",
		        }
		      ),
		    },
		    {
		      isRefTableList: true,
		      that: this,
		    }
		  );
		},
		//刷新 click
		btnReload() {
		  this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: networkregister2_listz,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选项表格 api（）
    onSelectData(url, modal, data, row, prop, page) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
		//关闭添加，修改框 click
		onCloseAdd(){
			if(this.editor) {
				this.editor.txt.html("");
			}
			this.editor.txt.clear();
		},
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		beforeDestroy() {
		   this.editor.destroy();
		   this.editor = null;
		 }
  },
  
};
</script>

<style  lang="scss">
.feerecv-w {
  padding: 10px;
  .search-form{
    padding: 0 0 10px 0;
  }
}
</style>